:root{
    --color-1: #f1f1f1;
    --color-2: #41046F;
    --color-3: #A668D5;
    //--color-4: #fff4e3;
    --color-4: var(--color-2);
    --color-5: var(--color-2);
    --color-6: var(--color-3);
    --color-7: transparent;
    --color-8: #333;
    --color-9: #f8f9fa;
    --color-white: #fff;

    --background-gradient: linear-gradient(to right, var(--color-1), var(--color-2)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    --background-gradient-inverse: linear-gradient(to right, var(--color-2), var(--color-1)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    --custom-font-family: 'Tourney', cursive;
}