@import '../node_modules/bootstrap/scss/bootstrap.scss';

.templatePurpleContainer {
	@extend .container;
}

.templatePurpleContainer-fluid {
	@extend .container-fluid;
}

.templatePurple-Row {
	@extend .row;
}
