@import "./vars";

/*Border Component END*/
html {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial;
  font-size: 14px;
  line-height: 1.42857143;
}

body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial;
  //font-family: var(--custom-font-family);
  font-size: 14px;
  line-height: 1.42857143;
}

.auth-box {
  display: flex;
  flex-direction: column;
}

.resultsTractListContainer {
  a.button {
    align-self: center;
    padding: 20px;
    background-color: #2c3e50;
    color: #fff;
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    border-radius: 8px;
  }

  a.share {
    //position: absolute;
    right: 35px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 2px);
    width: 26px;

    i{
      font-size: 17px;
      color: var(--color-5);
      transition: all 0.5s;
    }

    //&::before {
    //  position: relative;
    //  top: 0;
    //  display: inline-block;
    //  font-family: 'Glyphicons Halflings';
    //  content: '\e005';
    //  font-style: normal;
    //  font-weight: 400;
    //  line-height: 1;
    //  -webkit-font-smoothing: antialiased;
    //  -moz-osx-font-smoothing: grayscale;
    //  text-rendering: auto;
    //  font-size: 17px;
    //  color: var(--color-5);
    //  transition: all 0.5s;
    //}

    &:hover {
      text-decoration: none;
      transition: all 0.5s;

      &::before,
      i {
        color: var(--color-1);
        transition: all 0.6s;
      }
    }
  }

  a.link {
    &::before {
      transition: all 0.5s;
    }

    &:hover {
      text-decoration: none;
      transition: all 0.5s;

      &::before {
        color: var(--color-1);
        transition: all 0.6s;
      }
    }
  }
}

.auth-1 {
  background-color: var(--color-1);
  padding: 10px;
  border-radius: 8px 8px 0 0;
}

.auth-2 {
  background-color: var(--color-1);
  padding: 0 10px;
}

.auth-3 {
  background-color: var(--color-1);
  padding: 10px;
  margin-bottom: 50px;
  border-radius: 0 0 8px 8px;
}

.colorBackgroundGradient {
  background: var(--background-gradient);
}

.templatePurpleContainer {
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  position: relative;
}

.menu-top {
  padding-top: 20px;
}

.musicTop-head {
  background-color: #3699ff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.shadowBox {
  box-shadow: rgba(0, 0, 0, 0.1) 0 20px 25px -5px, rgba(0, 0, 0, 0.04) 0 10px 10px -5px;
}

.footerBox-bottom {
  position: relative;
}

.footerBox {
  .templatePurple-Row {
    width: 100%;
  }
}

.box-menu-padding-0 {
  padding-left: 0;
  padding-right: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.block-title {
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.popularTrackBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.yaSocialBox {
  min-height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.yaSocialBox--btnBox {
  position: relative;
  padding: 5px;
  border: 1px solid;
  border-color: var(--color-2);
}

.logo {
  min-height: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  display: flex;
  align-items: center;

  a {
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    line-height: normal;
    color: var(--color-2);
    text-decoration: none;
    font-size: 50px;
    padding: 5px;

    &:hover {
      i {
        transition: all 0.6s;
      }
    }

    i {
      margin-right: 10px;
      transition: all 0.5s;
    }
  }
}

.purpleCustomFontFamily {
  font-family: var(--custom-font-family);
}

/*Border Component*/
.borderBox {
  --width: 30%;
  --height: 70%;

  > * {
    background: #f8f8f8;
    position: relative;
    z-index: 2;
  }

  &::before {
    position: absolute;
    content: '';
    width: var(--width);
    height: var(--height);
    background: var(--background-gradient);
    top: -4px;
    left: -4px;
  }

  &::after {
    position: absolute;
    content: '';
    width: var(--width);
    height: var(--height);
    background: var(--background-gradient);
    right: -4px;
    bottom: -4px;
  }

  &.height-80 {
    --width: 20%;
    --height: 80%;
  }

  &.white-background-line {
    &::before {
      background: var(--color-white);
    }

    &::after {
      background: var(--color-white);
    }
  }

  &.revers-background {
    &::before {
      background: var(--background-gradient-inverse);
    }

    &::after {
      background: var(--background-gradient-inverse);
    }
  }

  &.revers-width {
    &::before {
      width: var(--height);
      height: var(--width);
    }

    &::after {
      width: var(--height);
      height: var(--width);
    }
  }

  &.middle-Border {
    &::before {
      top: -2px;
      left: -2px;
    }

    &::after {
      right: -2px;
      bottom: -2px;
    }
  }

  &.middle-Border-revers {
    &::before {
      top: auto;
      bottom: -2px;
      left: -2px;
    }

    &::after {
      right: -2px;
      bottom: auto;
      top: -2px;
    }
  }

  &.small-Border {
    &::before {
      top: -1px;
      left: -1px;
    }

    &::after {
      right: -1px;
      bottom: -1px;
    }
  }
}

/*Border Component END*/

.transform-rotateBox {
  transition: all 0.5s;

  &:hover {
    transform: rotate(-30deg);
    transition: all 0.6s;
  }
}

.musicSearch-contaniner {
  height: auto;

  input {
    -webkit-appearance: none;
    outline: none;
  }
}

.templatePurple-SearchWrap {
  position: relative;
  margin: 15px 0 20px 0;

  button {
    -webkit-appearance: none;
    outline: none;
  }

  #codeint {
    display: none;
  }
}

.templatePurple-SearchWrapBox {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid var(--color-2);
}

.inp {
  padding-right: 90px;
  background-color: transparent;
}

.string {
  box-sizing: border-box;
  width: 100%;
  border: 0;
  padding: 7px 10px;
  font-size: 20px;
  background-color: #fff;
  height: 70px;

  &:focus {
    outline: none;
  }
}

.searchArtist {
  position: absolute;
  top: 0;
  right: 80px;
  font-size: 14px;
  padding: 8px 7px;
  width: 144px;
  outline: none;
  background-color: #e7e7e7;
  cursor: pointer;
  color: #656565;
  border: 0;
  transition: all 0.2s ease;

  option {
    &:hover {
      background-color: #e7e7e7;
      color: #656565;
    }
  }
}

.siteTopWrapper {
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #f3f6f9;
}

#trackAjax {
  margin-top: 15px;
  margin-bottom: 10px;
  background: #fff;
  z-index: 1;

  .templatePurple-Row {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }
}

.templatePurple-topHeaderBox {
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 35px;
  padding: 7px 0;
  width: 90px;
  cursor: pointer;
  color: #fff;
  transition: all 0.2s ease;
  border: 1px solid #fff;
  height: 100%;
  background: var(--color-5);
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    margin-right: 3px;
    transition: all 0.5s;
  }

  &:hover {
    i {
      transition: all 0.6s;
    }
  }
}

#searchform {
  position: relative;
}

.navbar-dark {
  .navbar-brand {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-2);
    text-shadow: 1px 1px 0 #fff, 2px 2px 0 var(--color-2);
  }

  .navbar-nav {
    > li {
      > a {
        color: #fff;
      }
    }
    > .nav-item{
      .nav-link {
        display: flex;
        align-items: center;
        height: 50px;
        color: var(--color-white);
        position: relative;
        &::before{
          display: inline-block;
          position: absolute;
          content: '';
          right: -1px;
          width: 2px;
          height: 50%;
          background-color: var(--color-1);
          top: calc(25%);
        }
        &.home-link{
          display: flex;
          align-items: center;
          justify-content: center;
          i{

          }
          span{
            margin-left: 5px;
          }
        }

        &:focus {
          color: #333;
          background-color: var(--color-3);
        }

        &:hover {
          color: var(--color-white);
          background-color: var(--color-3);
        }
      }
      &:last-child{
        .nav-link{
          &::before{
            display: none;
          }
        }
      }
    }


    .show {
      .nav-link {
        color: var(--color-8);
        &.dropdown-toggle{
          color: var(--color-white);
        }

        &:focus {
          background-color: var(--color-3);
        }

        &:hover {
          background-color: var(--color-3);
        }
      }
    }

    .dropdown-menu {
      .nav-link {
        height: auto;
        padding: 3px 20px;
      }
    }
  }

  .navbar-toggler {
    border-color: #ddd;
  }
}

.navbar-brand {
  i {
    margin-right: 5px;
  }
}

.no-padding {
  padding-left: 0;
  padding-right: 0;
}

.navbar {
  position: relative;
  width: 100%;
  margin-bottom: 0;
  padding: 0 1rem;
  border-radius: 0;
  border: none;
  background-color: var(--color-2);
  //box-shadow: rgb(29, 43, 58) 0 0 5px 2px inset;
  .navbar-collapse {
    .dropdown-menu {
      &.show {
        border-radius: 0;
      }
    }
  }
  .yaSocialBox--btnBox{
    border-color: var(--color-1);
  }
}

.navbar-toggler {
  position: relative;
  padding: 6px 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 14px;
}

.menu-left {
  min-height: 120px;
}

.genres {
  display: block;
  padding-top: 10px;
  padding-bottom: 5px;

  a {
    font-size: 15px;
    display: inline-block;
    padding: 2px 5px;
    background-color: #117e0b;
    margin: 0 5px 5px 0;
    color: #fff;
    transition: all 0.15s ease;
    text-decoration: none;
    border: 1px solid #fff;

    &:hover {
      background-color: #f64e60 !important;
      color: #fff !important;
      text-decoration: none;
    }
  }
}

.selected {
  background-color: #f64e60 !important;
  color: #fff !important;
  text-decoration: none;
}

.content-list-sounds-mew {
  margin-bottom: 20px;
}

.glyphicon {
  top: 0;
  vertical-align: text-top;
}

.purple--Footer {
  padding: 50px 50px 50px;
  color: var(--color-2);

  p {
    span {
      font-family: var(--custom-font-family);
    }

    margin: 10px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  p.leftTextBoxLine {
    span {
      margin-right: 3px;
      margin-left: 6px;

      &.purpleCustomFontFamily{
        font-size: 20px;
      }
    }
  }

  .purple--Footer--infoBox {
    width: fit-content;
    text-align: right;
    font-family: var(--custom-font-family);
    margin: 10px 0;
    display: flex;
    align-items: center;
  }
}

.mail-to{
  a{
    color: var(--color-2);
  }
}
.footerTapLink {
  font-size: 25px;
  color: var(--color-5);

  &:hover {
    text-decoration: none;
    color: var(--color-5);
  }
}

.t-align-right {
  text-align: right;
}

.margin-bottom-60px {
  margin-bottom: 60px;
}

.list-search {
  display: none;
}

.templatePurple-topHeaderBox-site {
  margin-top: 150px;
}

.pleer {
  z-index: 99999;
  top: auto;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin: 0 auto;
  height: 120px;
  padding: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  background: var(--color-2);
  transition: all 0.6s ease;
}

.playerContainer--volumeBox {
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  display: inline-block !important;
  width: 102px;
  height: 18px;
  background: #fff;
  vertical-align: top;
  float: right;
  margin-top: 0;
  border: 2px solid #fff;
  //border-radius: 10px;

  .playerContainer--volumeLine {
    cursor: pointer;
    width: 40%;
    height: 14px;
    float: left;
    -webkit-transition: width 0.6s ease;
    transition: width 0.6s ease;
    background: var(--color-6);
    //border-radius: 10px;
  }
}



.artist-one {
  font-size: 18px;
  font-weight: 700;
}

.artist-two {
  font-size: 18px;
  font-weight: 700;
}

.templatePurple-popularTrackTitleContainer {
  width: 100%;
  background-color: var(--color-1);
  padding: 10px;
  //border-radius: 8px;
  .titleLine {
    border-bottom: 1px solid var(--color-2);
  }

  p {
    margin: 15px 0;
    color: var(--color-5);
  }
}

.templatePurple-popularTrackLine {
  margin: 15px 0;
  font-weight: 700;
  color: var(--color-2);

  i {
    margin-right: 5px;
  }
}

.seoTitleBox {
  p {
    span {
      text-transform: uppercase;
      font-weight: 700;
    }
  }
}

.resultTrackHeaderContainer {
  display: flex;
  align-items: center;
  min-height: 40px;
  border-bottom: 1px solid var(--color-5);
  margin-bottom: 10px;
  font-size: 20px;
}

.play-box {
  width: 50px;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-6);
}

.track-name-box {
  width: calc(100%);
  color: var(--color-6);
  //height: 20px;
}

.time-box {
  width: 65px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-6);
}

.share-box {
  width: 38px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-6);
}

.download-box {
  width: 42px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-6);
}

.resultTrackBox {
  height: 60px;
  display: flex;
  align-items: center;
  cursor: default !important;
  position: relative;
  transition: all 0.15s ease;
  margin-bottom: 10px;
  background: var(--color-white);
  //border-radius: 8px;
  //box-shadow: var(--color-2) 0px 1px 2px;
  box-shadow: var(--color-2) 0 1px 0;

  &:last-child {
    margin-bottom: 14px;
  }

  &:hover {
    background: var(--color-2);

    h2 {
      .title {
        color: #fff;

        a {
          color: #fff;
        }
      }
    }

    .left-b {
      color: #fff;
    }

    a.share {
      &::before,
      i{
        color: #fff;
      }
    }

    a.link {
      &::before,
      i{
        color: #fff;
      }
    }
  }

  a.start {
    cursor: pointer;
    width: 38px;
    height: 38px;
    background-color: var(--color-4);
    //border-radius: 50%;
    border: 1px solid var(--color-5);
    transition: all 0.8s ease;
    margin-left: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    color: var(--color-white);

    &::before {
      position: relative;
      display: inline-block;
      font-family: 'Glyphicons Halflings';
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: '\e072';
      margin-left: 2px;
    }

    &:hover {
      background-color: var(--color-3);
      transition: all 0.2s ease;
      text-decoration: none;
      &::before{
        color: var(--color-2);
      }
    }
  }

  a.link {
    cursor: pointer;
    //position: absolute;
    right: 1px;
    left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    height: calc(100% - 2px);
    width: 35px;
    border-radius: 0;
    i{
      font-size: 22px;
      color: var(--color-2);
    }

    //&::before {
    //  position: relative;
    //  top: 0;
    //  display: inline-block;
    //  font-family: 'Glyphicons Halflings';
    //  content: '\e202';
    //  font-style: normal;
    //  font-weight: 400;
    //  line-height: 1;
    //  -webkit-font-smoothing: antialiased;
    //  -moz-osx-font-smoothing: grayscale;
    //  text-rendering: auto;
    //  color: var(--color-2);
    //  font-size: 22px;
    //}

    &:hover {
      text-decoration: none;
      transition: all 0.2s ease;

      //&::before {
      //  color: #fff;
      //}
      i{
        color: #fff;
      }
    }
  }

  h2 {
    font-size: 1em;
    margin: 0 0 0 10px;
    line-height: normal;
    overflow: hidden;
    width: calc(100% - 158px);
    align-self: stretch;
  }
}

.resultTrackBox.pausing {
  a.start {
    &::before {
      position: relative;
      display: inline-block;
      font-family: 'Glyphicons Halflings';
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: '\e072';
      margin-left: 2px;
    }
  }

  background: var(--color-2);

  h2 {
    .title {
      color: #fff;

      a {
        color: #fff;
      }
    }
  }

  .left-b {
    color: #fff;
  }

  a.share {
    &::before,
    i{
      color: #fff;
    }
  }

  a.link {
    &::before,
    i{
      color: #fff;
    }
  }
}

.resultTrackBox.plays {
  a.start {
    &::before {
      content: '\e073';
      transform: rotate(0deg);
    }
  }

  background: var(--color-2);

  .start {
    border: 1px solid var(--color-2);
  }

  .pausing {
    border: 1px solid var(--color-2);
  }

  h2 {
    .title {
      color: #fff;

      a {
        color: #fff;
      }
    }
  }

  .left-b {
    color: #fff;
  }

  a.share {
    &::before,
    i{
      color: #fff;
    }
  }

  a.link {
    &::before,
    i{
      color: #fff;
    }
  }
}

.left-b {
  //position: absolute;
  right: 67px;
  left: auto;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 13px;
  color: var(--color-2);
  height: calc(100% - 2px);
  display: flex;
  align-items: center;
  margin: 0 0 0 10px;

  .dur {
    margin-right: 0;
    font-weight: 700;
    position: relative;
    line-height: normal;

    &::before {
      position: relative;
      margin-right: 5px;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: #3699ff;
    }
  }
}

h2 {
  .title {
    font-weight: normal;
    color: #545454;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;

    a {
      color: #545454;
      font-weight: 700;

      &:first-child {
        padding-right: 3px;
      }

      &:last-child {
        padding-left: 3px;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}

a.play {
  color: #fff;
  //border: none;

  &:hover {
    text-decoration: none;
  }
}

a.pause {
  color: #fff;
  border: none;

  &:hover {
    text-decoration: none;
  }
}

.play {
  width: 54px;
  height: 54px;
  margin: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 30px;
  //border-radius: 50%;
  //border: 2px solid #fff;

  &::before {
    position: relative;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\e072';
    margin-left: 3px;
  }
}

.pause {
  width: 54px;
  height: 54px;
  margin: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 30px;
  //border-radius: 50%;
  border: 1px solid #f64e60;

  &::before {
    position: relative;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\e073';
    margin-left: 3px;
  }
}

.playerContainer--CenterPlayBox-backBtn,
.playerContainer--CenterPlayBox-forwardBtn{
  width: 38px;
  height: 40px;
  margin: 0 -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
  font-size: 40px;
  margin-bottom: 5px;

  &::before{
    position: relative;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
.playerContainer--CenterPlayBox-backBtn{
  &::before{
    //content: '\e079';
  }
}
.playerContainer--CenterPlayBox-forwardBtn{
  &::before{
    //content: '\e080';
  }
}

.playerContainer--CenterPlayBox {
  width: 160px;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.playerContainer--ProgressBarContainer {
  cursor: pointer;
  transition: height linear 0.3s;
  position: absolute;
  top: auto;
  bottom: 5px;
  height: 10px;
  background-color: #f3f6f9;
  right: 0;
  color: #000;
  font-size: 10px;
  width: calc(50%);
  left: 25%;
  //border-radius: 10px;
}

.playerContainer--ProgressBarContainer--downloadBar {
  display: block;
  height: 10px;
  width: 100px;
  z-index: 201;
}

.playerContainer--ProgressBarContainer--playBar {
  position: relative;
  top: -1px;
  left: 1px;
  display: inline-block;
  height: 8px;
  background: var(--color-6);
  color: #fff;
  //border-radius: 10px;
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    right: 0;
    width: 6px;
    height: calc(100% + 10px);
    background: #ffffff;
    border: 2px solid #061161;
    display: none;
  }
}

.playerContainer {
  position: relative;
  //height: calc(100% - 20px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 30px;
}

.playerContainer--trackTimeBox {
  color: #fff;
  display: flex;
  justify-content: space-between;
  width: calc(50% + 150px);
  position: absolute;
  left: calc(25% - 75px);
  top: calc(50% + 18px);
  //bottom: 0;
  > span {
    i {
      margin-right: 5px;
    }
  }
}

.playerContainer--trackTimeBox--currentTime {
  line-height: normal;
  font-size: 25px;
  font-family: var(--custom-font-family);
  text-align: right;
  left: 0;
  top: auto;
  color: var(--color-1);
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.5);
}

.playerContainer--trackTimeBox--duration {
  line-height: normal;
  font-size: 25px;
  font-family: var(--custom-font-family);
  text-align: right;
  right: 0;
  left: auto;
  color: var(--color-1);
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.5);
}

.purpleCustomFontFamily{
  font-family: var(--custom-font-family);
}

.playerTrack {
  display: block;
  margin-left: 0;
  font-size: 14px;
  overflow: hidden;
  color: #fff;
  max-width: calc(250px - 60px);
  white-space: nowrap;
  text-overflow: ellipsis;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
}

.playerContainer--rightActionBox {
  display: flex;
  flex-direction: column;
  width: 210px;
  .yaSocialBox--btnBox{
    border-color: var(--color-white);
  }

  &-muteUnmute{
    width: 210px;
    margin-left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.loaded {
  display: none;
}

.uil {
  //display: inline-flex;
  //float: left;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: var(--color-7);

  &:hover {
    transition: all 1.2s ease;
  }
}

a.uil {
  color: #fff;

  &:hover {
    text-decoration: none;
  }
}

.uil.pause {
  //background-color: rgba(6, 23, 0, 0.6);
  //border: 2px solid var(--color-1);

  &:hover {
    background-color: transparent;
    //border: 2px solid var(--color-2);
  }
}

.uil.playerContainer--CenterPlayBox-forwardBtn {
  border-left: none;
  border-bottom: none;
}

.uil.playerContainer--CenterPlayBox-backBtn {
  border-right: none;
  border-top: none;
}

.playerContainer--leftWrapper {
  height: 100%;
  width: 246px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.playerTrack--icon {
  width: 35px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  //border: 2px solid #fff;
  font-size: 30px;
  margin-right: 8px;

  i {
    color: #fff;
  }
}

a.uir {
  &:hover {
    text-decoration: none;
  }
}

.uir {
  float: left;
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-right: 5px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;

  &:hover {
    opacity: 1;
  }
}

.repeat {
  margin-right: 30px;

  &::before {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.repeatOff {
  &::before {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  margin-right: 30px;
  opacity: 1;
}


.mute,
.unmute{
  &::before{
    position: relative;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 30px;
    color: #fff;
  }
}
.mute {
  &::before {
    content: '\e038';
  }
}
.unmute {
  &::before {
    content: '\e036';
  }
}

.templatePurple-topHeaderBox--socialBox {
  min-width: 50%;
}

.janry-musiki {
  text-align: center;
}

.site-left-gorizont-menu {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.nav-stacked {
  > li {
    display: block;
    border-bottom: 1px solid #f3f6f9;

    + {
      li {
        margin-top: 0;
      }
    }
  }
}

.templatePurple-AsideBox {
  > li {
    > a {
      border-radius: 0;
    }
  }

  .templatePurple-Aside-content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    h2 {
      overflow: visible;
      line-height: normal;
      width: 100%;
      padding: 0 5px;
      margin: 15px 0 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 20px;
      color: var(--color-6);

      .purpleFooterContainer & {
        justify-content: flex-start;
      }

      &:first-child {
        margin: 0 0 5px;
      }

      .glyphicon {
        width: 30px;
        height: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: var(--color-6);
        font-size: 17px;
      }
    }

    a {
      font-size: 15px;
      display: inline-flex;
      padding: 2px 5px;
      width: 100%;
      margin: 0 5px 5px 0;
      color: var(--color-5);
      text-decoration: none;
      border-bottom: 1px solid transparent;
      transition: 0.5s linear;
    }
  }

  .templatePurple-Aside-content.today-Download {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.list-sounds-new {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.nav {
  > li {
    > a {
      color: #444;
    }
  }
}

.footerSidebar {
  margin-bottom: 15px;

  .templatePurple-Aside-content--box-list {
    padding: 5px;
  }
}

.templatePurple-AsideBox.footerSidebar {
  .templatePurple-Aside-content {
    a {
      width: auto;
      border-bottom: 1px solid var(--color-2);
    }
  }
}

.templatePurple-Aside-content {
  a {
    &:hover {
      color: var(--color-1);
      text-decoration: none;
      //border-color: var(--color-5);
      background-color: var(--color-2);
    }
  }
}

.templatePurple-Aside-content--margin {
  width: 100%;
  margin-bottom: 15px;
}

.templatePurple-Aside-content--box-list {
  background: var(--color-1);
  padding: 3px 0;
  //border-radius: 8px;
  a {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.templatePurple-Aside-content--box-list.siteDotted-list {
  &.dotted-headphones{
    a{
      &::before{
        content: '\E035';
      }
    }
  }
  &.dotted-ok{
    a{
      &::before{
        content: '\E013';
      }
    }
  }
  &.dotted-qrcode{
    a{
      &::before{
        content: '\E039';
      }
    }
  }

  a {
    position: relative;
    padding-left: 20px;

    &:hover {
      &::before {
        color: var(--color-1);
      }
    }

    &::before {
      top: calc(50% - 7px);
      position: absolute;
      left: 4px;
      display: inline-block;
      font-family: 'Glyphicons Halflings';
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: '\E029';
      font-size: 14px;
      color: transparent;
    }
  }
}

.verticalLine{
  &::before{
    content: '';
    position: absolute;
    top: 10%;
    right: -1px;
    width: 2px;
    height: 80%;
    background-color: #d2d2d2;
  }
}

.templatePurple-Aside-content--box-list.honeycomb-mod {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  a {
    width: 100%;
    border: 1px solid rgba(82, 194, 52, 0.4);
    margin: 2px 5px;

    &:last-child {
      margin-bottom: 5px;
    }

    &:hover {
      border-bottom-color: rgba(6, 23, 0, 0.5);
    }
  }

  a.w-30 {
    width: calc(100% / 3 - 10px);

    &:hover {
      box-shadow: 200px 0 0 0 rgba(82, 194, 52, 0.3) inset;
    }
  }

  a.w-50 {
    width: calc(100% / 2 - 10px);

    &:hover {
      box-shadow: 300px 0 0 0 rgba(82, 194, 52, 0.3) inset;
    }
  }
}

.templatePurple-Aside-content--box-list.underline-link {
  a {
    border-bottom: 1px solid rgba(82, 194, 52, 0.4);

    &:hover {
      border-bottom-color: rgba(6, 23, 0, 0.5);
    }

    &:last-child {
      border-bottom-color: transparent;
    }
  }
}

.headerWrapper {
  position: relative;
  //background: var(--color-2);
  border-bottom: 10px solid var(--color-2);
  background-color: #f1f1f1;
}

.menu-box {
  position: relative;
  top: 10px;
}

.height-100P {
  height: 100%;
}

.background-color {
  background-color: var(--color-9);
}

.templatePurple-Aside--right {
  padding-top: 10px;
  padding-bottom: 10px;
}

.purpleFooterContainer {
  position: relative;
  margin-bottom: 120px;
  background: var(--color-9);
}

.relative {
  position: relative;
}

.dropdown-menu {
  min-width: 160px;
  white-space: nowrap;

  .nav-link {
    &:focus {
      background-color: var(--color-3);
    }

    &:hover {
      background-color: var(--color-3);
    }
  }
}

.dropdown.show {
  .nav-link.dropdown-toggle {
    background-color: var(--color-3);
  }
}


/*SHAREBOX*/
span.shareblock {
  z-index: 100;
  max-width: 40%;
  display: block;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -200px;
  border-radius: 5px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  text-align: center;
  border: 1px solid var(--color-2);
}

span.shareblock b {
  position: absolute;
  top: -13px;
  right: -13px;
  display: block;
  width: 27px;
  height: 27px;
  text-align: center;
  margin-bottom: -24px;
  background-color: #dddddd;
  cursor: pointer;
  border-radius: 30px;
  line-height: 26px;
}

span.shareblock h1 {
  padding: 10px 15px;
  margin: 0;
  background: var(--color-2);
  font-family: sans-serif;
  font-size: 20px;
  font-weight: 400;
  display: block;
  color: var(--color-white);
  border-bottom: 8px solid var(--color-3);
}

span.shareblock span {
  display: block;
  overflow: hidden;
  padding: 20px;
}

span.shareblock a {
  text-decoration: underline;
  display: block;
  float: left;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  font-size: 1.1em;
  background: #f2f2f2 url('../images/share.png') 5px 0 no-repeat;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -khtml-border-radius: 2px;
  margin: 10px 10px 0 0;
  padding: 0 10px 0 26px;
  font-family: sans-serif;
}

span.shareblock a.vk {
  background-position: 5px 0;
}

span.shareblock a.fb {
  background-position: 5px -30px;
}

span.shareblock a.tw {
  background-position: 5px -60px;
}

span.shareblock a.ok {
  background-position: 5px -90px;
}

span.shareblock a.mm {
  background-position: 5px -120px;
}

span.shareblock a.gp {
  background-position: 5px -150px;
}

#oframepleer{display:none!important;} 
#pleer{height:auto!important;}